import * as React from 'react'
import BlockContent from '../BlockContent'

export default (props) => {
  return(
    <section className="wrapper style1 page">
      <div className="inner">
        <div className="spotlights">
          <section>
            <div className="content">
              <div><BlockContent blocks={props.body} /> </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}

import * as R from 'ramda'
import React from 'react'
import { graphql, Link } from 'gatsby'

import { mapIndexed, notNilOrEmpty } from '../lib/Helpers'
import Layout from '../components/layout'
import HeroInternal from '../components/HeroInternal'
import SectionTwoColImg from '../components/sections/spotlights-two-col-image'
import SectionContent from '../components/sections/section-content'
import SectionOnlyContent from '../components/sections/section-only-content'
import TwoCols from '../components/sections/two-cols'
import config from '../../data/SiteConfig'

const PageTemplate = ({ data })=> {
    const page = data.sanityPage
    const { sanitySiteSettings: settings } = data

  const content = (page._rawContent || [])
    .filter((c) => !c.disabled)
    .map((c, i) => {
      let el = null
      switch (c._type) {
        case 'heroImage':
          el =
          <HeroInternal
            slug={page.slug.current}
            key={c._key}
            {...c}
          />
          break
        case 'section_content_image':
          el = <SectionTwoColImg key={c._key} {...c} />
          break
        case 'section_content':
          el = <SectionContent key={c._key} {...c} />
          break
        case 'section_content_only':
          el = <SectionOnlyContent key={c._key} {...c} />
          break
        case 'ctaColumns':
          el = <TwoCols key={c._key} {...c} />
          break
        default:
          el = null
      }
      return el
    })

    return (
      <Layout slug={page.slug.current}>
        {content}
      </Layout>
    )
}

export default PageTemplate

export const PageQuery = graphql`
  query($id: String!) {
    sanitySiteSettings {
      author
      description
      keywords
      title
      site_url
      seo_image {
        asset {
          url
        }
      }
      logo {
        asset {
          fluid(maxHeight: 700) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
   sanityPage(id: { eq: $id }) {
      _rawContent(resolveReferences: {maxDepth: 4})
      slug {
        current
      }
      pageTitle
    }
  }
`

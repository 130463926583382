import * as React from 'react'
import BlockContent from '../BlockContent'
import { mapIndexed, notNilOrEmpty } from '../../lib/Helpers'

export default (props) => {
  return(
    <section className="wrapper style1 page">
      <div className="inner">
        <div className="spotlights">
          <section>
            <div className="content">
              <div class="row">
                {
                  mapIndexed( (col, index) => {
                    return(
                      <div class="col-6 col-12-small" key={index}>
                        <BlockContent blocks={col.body} />
                      </div>
                    )

                  })(props.columns)
                }
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}

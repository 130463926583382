import React from 'react'

const HeroInternal = props => {
  return (
    <section
      id="banner"
      className={`hero--internal hero--${props.slug}`}
      style={{
        backgroundImage: `url(${props.image.asset.url})`,
      }}
    />
  )
}

export default HeroInternal
